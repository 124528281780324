import axios from 'axios'
import {
  getReporteComprobanteSolicitud,
  getExcelConsultaSolocitudes,
} from '@/utils/paths'

const pathGetReporteComprobanteSolicitud = getReporteComprobanteSolicitud
const pathGetExcelConsultaSolocitudes = getExcelConsultaSolocitudes

export function reporteResource() {
  function getComprobante(idSolicitud) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${pathGetReporteComprobanteSolicitud}comprobante/${idSolicitud}`, { responseType: 'blob' })
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function getReporteExcelConsultaSolicitudes(consultaBeneSolicitudFiltroDto) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${pathGetExcelConsultaSolocitudes}solicitudes/`, consultaBeneSolicitudFiltroDto, { responseType: 'blob' })
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  return {
    getComprobante,
    getReporteExcelConsultaSolicitudes,
  }
}
