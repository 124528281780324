import axios from 'axios'
import {
  postConsultaSolicitudFilter,
  putSolicitudEstado,
  postComentario,
  postSaveEstadoUpdateComentario,
  postEntregaSolicitud,
  postNotificarRechazo,
} from '@/utils/paths'

const pathPostConsultaSolicitudFilter = postConsultaSolicitudFilter
const pathPutSolicitudEstado = putSolicitudEstado
const pathPostComentario = postComentario
const pathPostSaveEstadoUpdateComentario = postSaveEstadoUpdateComentario
const pathPostEntregaSolicitud = postEntregaSolicitud
const pathPostNotificarRechazo = postNotificarRechazo

export function consultaSolicitudResource() {
  function findSolicitudesFilter(consultaBeneSolicitudFiltroDto) {
    return new Promise((resolve, reject) => {
      axios
        .post(pathPostConsultaSolicitudFilter, consultaBeneSolicitudFiltroDto)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function updateSolicitudEstado(idSolicitud, codigoEstado, solicitudEstado) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${pathPutSolicitudEstado + idSolicitud}/estado/${codigoEstado}`, solicitudEstado)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function saveComentario(idSolicitud, comentario) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${pathPostComentario + idSolicitud}/comentario/`, comentario)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function updateSolicitudEstadoSaveComentario(idSolicitud, codigoEstado, comentarioDto, solicitudEstadoDto) {
    return new Promise((resolve, reject) => {
      const comentarioSolicitudDto = {
        comentarioDto,
        solicitudEstadoDto,
      }
      axios
        .post(`${pathPostSaveEstadoUpdateComentario + idSolicitud}/estadocomentario/${codigoEstado}`, comentarioSolicitudDto)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function generarEntregaSolicitud(idSolicitud, solicitudEstado) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${pathPostEntregaSolicitud + idSolicitud}`, solicitudEstado)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function notificarRechazo(idSolicitud) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${`${pathPostNotificarRechazo}rechazo/${idSolicitud}`}`)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  return {
    findSolicitudesFilter,
    updateSolicitudEstado,
    saveComentario,
    updateSolicitudEstadoSaveComentario,
    generarEntregaSolicitud,
    notificarRechazo,
  }
}
