import axios from 'axios'
import {
  getBeneficioDetalle,
  getSolicitudesByBeneficioId,
  postSolicitudesFilter,
  getBeneficios,
  saveSolicitudes,
  saveSolicitud,
  getCatSolicitante,
  postUploadFileAttachment,
  postUploadFilesAttachment,
  getFindInfoAdjuntos,
  getAdjunto,
  getListaSelectBeneficios,
  getListaSelectBeneficiosFromSolicitudes,
  postSaveSolicitudQuincho,
  getSolicitudesPreviasQuincho,
  postVerificarYCompletarSolicitud,
} from '@/utils/paths'

const pathGetBeneficioDetalle = getBeneficioDetalle
const pathGetSolicitudesByBeneficioId = getSolicitudesByBeneficioId
const pathGetBeneficios = getBeneficios
const postSaveSolicitudes = saveSolicitudes
const postSaveSolicitud = saveSolicitud
const pathGetCatSolicitante = getCatSolicitante
const pathUploadFileAttachment = postUploadFileAttachment
const pathUploadFilesAttachment = postUploadFilesAttachment
const pathGetInfoAdjuntos = getFindInfoAdjuntos
const pathGetAdjunto = getAdjunto
const pathPostSolicitudesFilter = postSolicitudesFilter
const pathGetListaSelectBeneficios = getListaSelectBeneficios
const pathGetListaSelectBeneficiosFromSolicitudes = getListaSelectBeneficiosFromSolicitudes
const pathpPostSaveSolicitudQuincho = postSaveSolicitudQuincho
const pathGetSolicitudesPreviasQuincho = getSolicitudesPreviasQuincho
const pathPostVerificarYCompletarSolicitud = postVerificarYCompletarSolicitud

export function solicitudResource() {
  function findSolicitudesByBeneficioId(beneficioId) {
    return new Promise((resolve, reject) => {
      axios
        .get(pathGetSolicitudesByBeneficioId + beneficioId)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function findBeneficioById(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(getBeneficios + id)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function findBeneficios() {
    return new Promise((resolve, reject) => {
      axios
        .get(pathGetBeneficios)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function findBeneficioDetalle(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(pathGetBeneficioDetalle + id)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function generarSolicitud(solocitud) {
    return new Promise((resolve, reject) => {
      axios
        .post(postSaveSolicitud, solocitud)
        // .post(`${postSaveSolicitudes}`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function generarSolicitudes(solicitudes) {
    return new Promise((resolve, reject) => {
      axios
        .post(postSaveSolicitudes, solicitudes)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function getSolicitudesFilter(idBeneficio, listaDniAfiliadoFamilia) {
    return new Promise((resolve, reject) => {
      axios
        .post(pathPostSolicitudesFilter + idBeneficio, listaDniAfiliadoFamilia)
        // .post(`${postSaveSolicitudes}`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function getAllCategoriaSolicitante() {
    return new Promise((resolve, reject) => {
      axios
        .get(
          pathGetCatSolicitante,
        )
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function uploadFileAttachment(solicitudId, adjunto) {
    return new Promise((resolve, reject) => {
        const formData = new FormData() // eslint-disable-line
      formData.append('adjunto', adjunto)
      axios
        .post(`${pathUploadFileAttachment}/${solicitudId}`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function uploadFilesAttachment(solicitudId, adjuntos) {
    return new Promise((resolve, reject) => {
        const formData = new FormData() // eslint-disable-line
      adjuntos.forEach(adjunto => {
        formData.append('adjuntos', adjunto)
      })

      axios
        .post(`${pathUploadFilesAttachment}/${solicitudId}`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function getInfoAdjuntos(idSolicitud) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          pathGetInfoAdjuntos + idSolicitud,
        )
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function getAdjuntoByte(idAdjunto) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          pathGetAdjunto + idAdjunto, { responseType: 'blob' },
        )
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function getListaBeneficiosSelect() {
    return new Promise((resolve, reject) => {
      axios
        .get(pathGetListaSelectBeneficios)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function getListaBeneficiosSelectFromSolicitudes(cuil) {
    return new Promise((resolve, reject) => {
      axios
        .get(pathGetListaSelectBeneficiosFromSolicitudes + cuil)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function generarSolicitudQuincho(solocitudDto) {
    return new Promise((resolve, reject) => {
      axios
        .post(pathpPostSaveSolicitudQuincho, solocitudDto)
        // .post(`${postSaveSolicitudes}`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function getSolicitudesPrevQuincho(idBeneficio, cuil) {
    return new Promise((resolve, reject) => {
      axios
        .post(pathGetSolicitudesPreviasQuincho + idBeneficio, cuil)
        // .post(`${postSaveSolicitudes}`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function verificarycompletarsolicitud(listaVerificacion) {
    return new Promise((resolve, reject) => {
      axios
        .post(pathPostVerificarYCompletarSolicitud, listaVerificacion)

        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  return {
    findSolicitudesByBeneficioId,
    findBeneficioById,
    getSolicitudesFilter,
    findBeneficios,
    generarSolicitudes,
    generarSolicitud,
    findBeneficioDetalle,
    getAllCategoriaSolicitante,
    uploadFileAttachment,
    uploadFilesAttachment,
    getInfoAdjuntos,
    getAdjuntoByte,
    getListaBeneficiosSelect,
    getListaBeneficiosSelectFromSolicitudes,
    generarSolicitudQuincho,
    getSolicitudesPrevQuincho,
    verificarycompletarsolicitud,
  }
}
