/* eslint-disable no-shadow */
import axios from 'axios'
import {
  getSolicitudEstado,
} from '@/utils/paths'

const pathGetSolicitudEstado = getSolicitudEstado

export function solicitudEstadoResource() {
  function getAllSolicitudEstado() {
    return new Promise((resolve, reject) => {
      axios
        .get(
          pathGetSolicitudEstado,
        )
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }

  return {
    getAllSolicitudEstado,
  }
}
