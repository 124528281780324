<template>
  <div :style="`border: 1px inset ${getColorEstado}`">
    <b-card class="m-0">
      <p>Afiliado</p>
      <b-row>
        <b-col md="6">
          <b-form-group
            label-for="input-horizontal"
            label="C.U.I.L."
          >
            <h5>{{ detalleSolicitudFormDto.solicitanteCuil }}</h5>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label-for="input-horizontal"
            label="Nombre"
          >
            <h5>{{ detalleSolicitudFormDto.usuario.apellido }}, {{ detalleSolicitudFormDto.usuario.nombre }}</h5>
          </b-form-group>
        </b-col>
      </b-row>
      <p>Beneficiario</p>
      <b-row>
        <b-col md="4">
          <b-form-group
            label-for="input-horizontal"
            label="DNI"
          >
            <h5><strong>{{ detalleSolicitudFormDto.beneficiarioDni }}</strong></h5>
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
            label-for="input-horizontal"
            label="Nombre"
          >
            <h5><strong>{{ detalleSolicitudFormDto.beneficiarioNombre }}</strong></h5>
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
            label-for="input-horizontal"
            label="Fecha Nacimiento"
          >
            <h5><strong> {{ formatearFechaNac() }} </strong></h5>
          </b-form-group>
        </b-col>
      </b-row>
      <p>Beneficio</p>
      <b-row>
        <b-col md="4">
          <b-form-group
            label-for="input-horizontal"
            label="Producto"
          >
            <h5><strong>{{ detalleSolicitudFormDto.solconprod.producto.nombre }}</strong></h5>
          </b-form-group>
        </b-col>
        <b-col md="2">
          <b-form-group
            label-for="input-horizontal"
            label="Estado"
          >
            <h4 :style="`color:${getColorEstado}`">
              {{ estado }}
            </h4>
          </b-form-group>
        </b-col>
        <b-col
          v-if="detalleSolicitudFormDto.motivoRechazo !== null && detalleSolicitudFormDto.motivoRechazo !== undefined"
          md="6"
        >
          <b-form-group label="Motivo rechazo">
            <quill-editor
              v-if="detalleSolicitudFormDto.motivoRechazo !== null && detalleSolicitudFormDto.motivoRechazo !== undefined"
              id="txtComentario"
              v-model="detalleSolicitudFormDto.motivoRechazo"
              theme="snow"
              disabled
              :options="editorOption"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12">
          Retira <span>{{ quienRetira }} </span>

          <b-form-group v-if="!detalleSolicitudFormDto.solconprod.retiraUsuario">
            <b-row>
              <b-col md="4">
                <b-form-group label="Dni">
                  <h5><strong>{{ detalleSolicitudFormDto.solconprod.retiraDni }}</strong></h5>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group label="Apellido">
                  <h5><strong>{{ detalleSolicitudFormDto.solconprod.retiraApellido }}</strong></h5>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group label="Nombre">
                  <h5><strong>{{ detalleSolicitudFormDto.solconprod.retiraNombre }}</strong></h5>
                </b-form-group>
              </b-col>
            </b-row>
          </b-form-group>
        </b-col>
      </b-row>
      <!--<hr v-if="mostrarSeccionAdjuntos">-->
      <!--<h5 v-if="mostrarSeccionAdjuntos">-->

      <hr>
      <h5>
        Adjuntos
      </h5>
      <b-card no-body>
        <b-overlay
          v-if="isBusyAdjuntos"
          no-wrap
          :show="isBusyAdjuntos"
          :rounded="overlayConfig.rounded"
          :variant="overlayConfig.variant"
          :opacity="overlayConfig.opacity"
          :blur="overlayConfig.blur"
        >
          <template #overlay>
            <div v-if="isBusyAdjuntos">
              <div style="text-align: center">
                <span class="spinner-border p-1" />

              </div>
              <div style="text-align: center" />
              <span class="p-2">{{ overlayConfig.message }}</span>
            </div>
          </template>
        </b-overlay>
        <b-row>
          <b-col md="12">
            <div v-if="infoAdjuntos.length === 0">
              <p>No hay adjuntos</p>
            </div>
            <div v-if="infoAdjuntos.length > 0">
              <div
                v-for="(adjunto, index) in infoAdjuntos"
                :key="index"
              >
                <b-row>
                  <b-col md="1">
                    <small
                      v-if="adjunto.isDownloading"
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    />
                    <font-awesome-icon
                      v-if="!adjunto.isDownloading"
                      v-b-tooltip.hover.right="'Clic para descargar'"
                      style="cursor:pointer"
                      size="lg"
                      icon="fa-solid fa-cloud-arrow-down"
                      @click="adjunto.isDisabled ? null:descargarAdjunto(adjunto, index)"
                    />
                  </b-col>
                  <b-col md="11">
                    <p
                      v-b-tooltip.hover="'Descargar '+getMBfromBytes(adjunto.adjuntoSize) + 'MB'"
                      style="cursor:pointer"
                      @click="adjunto.isDisabled ? null:descargarAdjunto(adjunto, index)"
                    >

                      {{ adjunto.nombreAdjunto }}

                    </p>
                  </b-col>

                </b-row>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-card>
    </b-card>
    <hr v-if="showBtnRechazar || showBtnAprobar">
    <b-row
      v-if="showBtnRechazar || showBtnAprobar"
      class="justify-content-md-center mt-1"
    >
      <b-col md="2">
        <b-button
          v-if="showBtnRechazar"
          size="sm"
          squared
          variant="danger"
          :disabled="disabledBtnRechazar"
          @click="rechazarSolicitud()"
        >
          Rechazar
          <span
            v-if="detalleSolicitudFormDto.isDoing.rechazandoSolicitud"
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          />
        </b-button>
      </b-col>
      <b-col md="2">
        <!--variant="success"-->
        <b-button
          v-if="showBtnAprobar"
          size="sm"
          squared
          variant="success"
          :disabled="disabledBtnAprobar"
          @click="aprobarSolicitud()"
        >
          Aprobar
          <span
            v-if="detalleSolicitudFormDto.isDoing.aprobandoSolicitud"
            class="spinner-border spinner-border-sm"
            aria-hidden="true"
          />
        </b-button>

      </b-col>
    </b-row>
    <br>
    <div v-if="showComentarioModal">
      <comentario-modal
        :show-comentario-modal="showComentarioModal"
        @contentComentarioModal="contentComentarioModal"
      />
    </div>
  </div>
</template>
<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import { mapGetters } from 'vuex'
import { quillEditor } from 'vue-quill-editor'
import { solicitudResource } from '@/services/solicitudResource'
import { consultaSolicitudResource } from '@/services/consultaSolicitudResource'
import { fileManagement } from '@/utils/fileManagement'
import useAppConfig from '@core/app-config/useAppConfig'
import ComentarioModal from './ComentarioModal.vue'

export default {
  components: {
    ComentarioModal,
    quillEditor,
  },
  props: {
    detalleSolicitud: {
      type: Object,
      required: true,
      default: undefined,
    },
  },
  data() {
    return {
      isEditComentarioModal: false,
      isWorkingAprobar: false,
      editorOption: {
        modules: {
          toolbar: false,
        },
        placeholder: '',
        theme: 'snow',
        readOnly: 'true',
      },
      overlayConfig: {
        rounded: 'sm',
        variant: 'transparent',
        opacity: '0.5',
        blur: '5px',
        message: 'Buscando lista de adjuntos',
      },
      comentarioDto: {
        comentario: null,
      },
      showComentarioModal: false,
      detalleSolicitudFormDto: {},
      infoAdjuntos: [],
      isBusyAdjuntos: false,
      nameModulo: 'CONSULTA_BENEFICIO',
    }
  },
  computed: {
    estado() {
      return this.detalleSolicitudFormDto.solicitudEstado.nombreCustom
    },
    quienRetira() {
      let retira = ''
      if (this.detalleSolicitudFormDto.solconprod.retiraUsuario) {
        retira = 'AFILIADO'
      } else {
        retira = 'Otra persona'
      }
      return retira
    },
    mostrarSeccionAdjuntos() {
      return this.detalleSolicitudFormDto.beneficio.solicitarAdjuntos
    },
    isGestor() {
      let isGestor = false
      const someGestor = this.currentUser.roles.filter(rol => rol.codigo === 'GESTOR_BENEFICIOS')
      if (someGestor.length > 0) {
        isGestor = true
      }
      return isGestor
    },
    getColorEstado() {
      const { skin } = useAppConfig()
      let whiteBlack = 'black'
      if (skin.value === 'dark') {
        whiteBlack = 'white'
      }
      let color = whiteBlack
      if (this.detalleSolicitud.solicitudEstado.codigo === '003') {
        // Rechazada
        color = '#FC1C1C'
      }
      if (this.detalleSolicitud.solicitudEstado.codigo === '001') {
        // Aprobada
        color = '#53D135'
      }
      if (this.detalleSolicitud.solicitudEstado.codigo === '004') {
        // Cancelada
        color = '#FFA07A'
      }
      if (this.detalleSolicitud.solicitudEstado.codigo === '005') {
        // Etregada
        color = '#58D3F7'
      }
      return color
    },
    disabledBtnAprobar() {
      return this.detalleSolicitudFormDto.isDoing.rechazandoSolicitud || this.detalleSolicitudFormDto.isDoing.aprobandoSolicitud
    },
    disabledBtnRechazar() {
      return this.detalleSolicitudFormDto.isDoing.rechazandoSolicitud || this.detalleSolicitudFormDto.isDoing.aprobandoSolicitud
    },
    showBtnAprobar() {
      const cumpleCondicionEstado = this.detalleSolicitudFormDto.solicitudEstado.nombre === 'PENDIENTE'
      return this.isGestor && cumpleCondicionEstado
    },
    showBtnRechazar() {
      const cumpleCondicionEstado = this.detalleSolicitudFormDto.solicitudEstado.nombre === 'PENDIENTE'

      return this.isGestor && cumpleCondicionEstado
    },
    ...mapGetters(
      {
        currentUser: 'getCurrentUser',
        permisosVer: 'getPermisosVer',
        permisosDel: 'getPermisosDel',
        permisosIns: 'getPermisosIns',
        permisosUpd: 'getPermisosUpd',
      },
    ),
  },
  watch: {
    detalleSolicitud: {
      deep: true,
      handler() {
        this.detalleSolicitudFormDto = this.detalleSolicitud
      },
    },
  },
  created() {
    this.detalleSolicitudFormDto = { ...this.detalleSolicitud }
    this.getAdjuntosInfo(this.detalleSolicitudFormDto.id)
  },
  methods: {
    changethis() {
      this.showComentarioModal = false
      return null
    },
    formatearFechaNac() {
      let fecha = '-'
      if (this.detalleSolicitudFormDto.beneficiarioFechaNac !== null) {
        fecha = this.formatDateTable(this.detalleSolicitudFormDto.beneficiarioFechaNac, 'DD/MM/YYYY')
      }
      return fecha
    },
    async contentComentarioModal({ comentarioDto }) {
      await this.changethis()
      if (comentarioDto !== null) {
        this.detalleSolicitudFormDto.isDoing.rechazandoSolicitud = true
        this.comentarioDto = { ...comentarioDto }
        this.updateEstadoSaveComentario('003')
      } else {
        this.comentarioDto.comentario = null
      }
    },
    getAdjuntosInfo() {
      // this.isBusyAdjuntos = true
      this.infoAdjuntos = this.detalleSolicitudFormDto.solicitudAdjunto
      /*
      solicitudResource().getInfoAdjuntos(idSolicitud).then(results => {
        this.infoAdjuntos = [...results]
      }).catch(error => {
        console.error('error', error)
      })
        .finally(() => {
          this.isBusyAdjuntos = false
        })
        */
    },
    descargarAdjunto(adjunto, index) {
      this.infoAdjuntos[index].isDownloading = true
      // eslint-disable-next-line no-param-reassign
      this.infoAdjuntos.forEach(adjuntoElement => {
        // eslint-disable-next-line no-param-reassign
        adjuntoElement.isDisabled = true
      })
      solicitudResource().getAdjuntoByte(adjunto.id).then(resBytes => {
        fileManagement().getAdjunto(resBytes, adjunto.nombreAdjunto, adjunto.tipoAdjunto)
        // const blob = new Blob([resBytes], { type: 'application/x-www-form-urlencoded' })
        // saveAs(blob, adjunto.item.nombreAdjunto)
        this.infoAdjuntos[index].isDownloading = false
      }).catch(error => {
        console.error('error', error)
      })
        .finally(() => {
          this.infoAdjuntos[index].isDownloading = false
          // eslint-disable-next-line no-param-reassign
          this.infoAdjuntos.forEach(adjuntoElement => { adjuntoElement.isDisabled = false })
        })
    },
    rechazarSolicitud() {
      this.showComentarioModal = true
    },
    aprobarSolicitud() {
      this.detalleSolicitudFormDto.isDoing.aprobandoSolicitud = true
      this.updateEstado('001')
    },
    updateEstado(codigoEstado) {
      const idSolicitud = this.detalleSolicitudFormDto.id
      consultaSolicitudResource().updateSolicitudEstado(idSolicitud, codigoEstado, this.detalleSolicitudFormDto.solicitudEstado).then(result => {
        this.detalleSolicitudFormDto = { ...result }
        this.$emit('changeItemIndex', { detalleSolicitud: this.detalleSolicitudFormDto })
      }).catch(error => {
        console.error('error', error)
      })
        .finally(() => {
          this.detalleSolicitudFormDto.isDoing.aprobandoSolicitud = false
        })
    },
    updateEstadoSaveComentario(codigoEstado) {
      const errDetail = 'No es posible enviar el correo de notificación, deberá comunicarle la novedad al afiliado por otro medio'
      const idSolicitud = this.detalleSolicitudFormDto.id
      consultaSolicitudResource().updateSolicitudEstadoSaveComentario(idSolicitud, codigoEstado, this.comentarioDto, this.detalleSolicitudFormDto.solicitudEstado).then(result => {
        this.detalleSolicitudFormDto = { ...result }
        consultaSolicitudResource().notificarRechazo(result.id).then(() => {
        }).catch(error => {
          console.error('error:', error)
          this.showMessageBig('Envío de email', 'error', `${errDetail} <br> ${error.data.error}<hr>`)
        })
        this.$emit('changeItemIndex', { detalleSolicitud: this.detalleSolicitudFormDto })
      }).catch(error => {
        console.error('error', error)
      })
        .finally(() => {
          this.detalleSolicitudFormDto.isDoing.rechazandoSolicitud = false
          this.showComentarioModal = false
        })
    },
    showMessageBig(titleMessage, icon_, htmlErrors) {
      this.customizeSweetAlert2()
      const htmlText = `<p style="color:${this.swalConfig.textColor}">${htmlErrors}</p>`
      this.$swal({
        allowOutsideClick: false,
        title: titleMessage,
        html: htmlText,
        icon: icon_,
        // showCancelButton: true,
        confirmButtonText: 'Cerrar',
        background: this.swalConfig.background,
        // cancelButtonText: 'No',

        customClass: {
          confirmButton: 'btn btn-primary square',
          cancelButton: 'btn btn-danger square',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          //
        }
      })
    },
  },
}
</script>
